import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';

import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import { DocsLayout } from '@/components/shared/docs/DocsLayout';
import { LpLayout } from '@/components/shared/lp/LpLayout';

import '@/styles/globals.scss';

import { siteData } from '@/data/site';

// export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
//   getLayout?: (page: ReactElement) => ReactNode;
// };

// type AppPropsWithLayout = AppProps & {
//   Component: NextPageWithLayout;
// };

export default function App({ Component, pageProps }: AppProps) {
  let router = useRouter();
  const pathname = router.pathname;
  const matomoContainerId = siteData.MATOMO_CONTAINER_ID;

  function cacheBuster() {
    function pad(number: number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    }

    var today = new Date();

    return (
      today.getUTCFullYear() +
      '-' +
      pad(today.getUTCMonth() + 1) +
      '-' +
      pad(today.getUTCDate()) +
      '-' +
      pad(today.getUTCHours()) +
      '-' +
      pad(today.getUTCMinutes())
      //+      '-'
      // +       pad(today.getUTCSeconds())
    );
  }

  useEffect(() => {
    if (matomoContainerId !== '') {
      var _mtm = ((window as any)._mtm = (window as any)._mtm || []);
      _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.crossOrigin = 'anonymous';
      g.src = `https://matomo.komodoplatform.com/js/${matomoContainerId}.js?${cacheBuster()}`;
      if (s && s.parentNode) {
        s.parentNode.insertBefore(g, s);
      }
    }
  }, [matomoContainerId, pathname]);

  useEffect(() => {
    const html = document.documentElement;
    const { query, asPath, pathname } = router;
    if (query && query.lang && !Array.isArray(query.lang)) {
      html.setAttribute('lang', query.lang);
    } else if (pathname !== '/404') {
      html.setAttribute('lang', asPath.split('/')[1] || 'en');
    }
  }, [router]);

  /*const getLayout = Component.getLayout || ((page) => page);

  return (
    <RecoilRoot>
      <MetaHead pageProps={pageProps} />
      {getLayout(<Component {...pageProps} />)}
    </RecoilRoot>
  );*/

  return (
    <RecoilRoot>
      {router.pathname.startsWith('/en/docs') ? (
        <DocsLayout pageProps={pageProps}>
          <Component {...pageProps} />
        </DocsLayout>
      ) : (
        <LpLayout pageProps={pageProps}>
          <Component {...pageProps} />
        </LpLayout>
      )}
    </RecoilRoot>
  );
}
